/**
 * File posts-slider.js
 *
 * Handle the slider in home page and Gutenberg block.
 * Use Splide.js.
 */

import Splide from '@splidejs/splide/dist/js/splide.min.js';

document.addEventListener( 'DOMContentLoaded', function () {
	const sliders = document.querySelectorAll( '.posts-slider' );

	if ( ! sliders ) {
		return;
	}

	sliders.forEach( function ( slider ) {
		const splide = new Splide( slider );
		splide.mount();
	} );
} );
