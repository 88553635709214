/**
 * File: off-canvas.js
 *
 * Help deal with the off-canvas mobile menu.
 */

// TODO: change comments

// Make sure everything is loaded first.
if (
	( 'complete' === document.readyState ||
		'loading' !== document.readyState ) &&
	! document.documentElement.doScroll
) {
	handleToggleSubmenu();
} else {
	document.addEventListener( 'DOMContentLoaded', handleToggleSubmenu );
}

/**
 * Kick off our off canvas functions.
 *
 * @author Corey Collins
 * @since January 31, 2020
 */
function handleToggleSubmenu() {
	const button = document.querySelector( '.category-submenu__toggle' );

	if ( ! button ) {
		return;
	}
	button.addEventListener( 'click', toggleSubmenu );
	const submenu = document.querySelector( '.category-submenu__nav' );

	/**
	 * Handle closing the off-canvas overlay.
	 *
	 * @author Corey Collins
	 * @since January 31, 2020
	 */
	function closeSubmenu() {
		submenu.classList.remove( 'is-visible' );
		button.classList.remove( 'is-rotated' );
		button.setAttribute( 'aria-expanded', false );
		submenu.setAttribute( 'aria-hidden', true );
	}

	/**
	 * Toggle the display of the off-canvas overlay.
	 *
	 * @author Corey Collins
	 * @since January 31, 2020
	 */
	function toggleSubmenu() {
		if ( 'true' === button.getAttribute( 'aria-expanded' ) ) {
			closeSubmenu();
		} else {
			openSubmenu();
		}
	}

	/**
	 * Handle opening the off-canvas overlay.
	 *
	 * @author Corey Collins
	 * @since January 31, 2020
	 */
	function openSubmenu() {
		submenu.classList.add( 'is-visible' );
		button.classList.add( 'is-rotated' );
		button.setAttribute( 'aria-expanded', true );
		submenu.setAttribute( 'aria-hidden', false );
	}
}
