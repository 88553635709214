/**
 * Template Tag JS
 *
 * JS for functions in template-tags.php.
 */

import './mobile-menu';
import './navigation-primary';
import './off-canvas';
import './page-cta';
import './category-submenu';
