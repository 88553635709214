/**
 * Home scripts
 *
 * JS for functions in front-page.php
 */

import aos from 'aos';

document.addEventListener( 'DOMContentLoaded', () => {
	aos.init();
} );
