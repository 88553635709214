/**
 * File page-cta.js
 *
 * JS scripts for page CTA functionality.
 */

// Make sure everything is loaded first.
if (
	( 'complete' === document.readyState ||
		'loading' !== document.readyState ) &&
	! document.documentElement.doScroll
) {
	pageCtaScripts();
} else {
	document.addEventListener( 'DOMContentLoaded', pageCtaScripts );
}

/**
 * Handle our page CTA scripts.
 *
 * @since 1.0.0
 */
function pageCtaScripts() {
	const cta = document.querySelector( '.page-cta' );

	if ( ! cta ) {
		return;
	}

	const footer = document.querySelector( '.site-footer' );

	window.addEventListener( 'scroll', () => {
		const contentRect = cta.getBoundingClientRect();
		const footerRect = footer.getBoundingClientRect();

		if ( contentRect.bottom >= footerRect.top ) {
			// Content is touching or overlapping with the footer
			cta.classList.add( 'page-cta--inverse' );
		} else {
			// Content is not touching the footer
			cta.classList.remove( 'page-cta--inverse' );
		}
	} );
}
